
const initState = [];

export default (state = initState, action) => {
  let newState;

  switch (action.type) {
    case 'INIT': 
      if (typeof localStorage !== 'undefined') {
        const items = JSON.parse(localStorage.getItem('dischr-artworks-cart'));
        newState = (items && Object.keys(items).length > 0) ? items : [];
      }
      return newState || state;
    case 'ADD_ITEM':
      newState = Object.keys(state).length > 0 ? [...state, action.item] : [action.item];
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('dischr-artworks-cart', JSON.stringify(newState));
      }
      return newState;
    case 'REMOVE_ITEM':
      newState = state.filter(({ id }) => id !== action.id);
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('dischr-artworks-cart', JSON.stringify(newState));
      }
      return newState;
    case 'CLEAR':
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('dischr-artworks-cart');
      }
      return []
    default:
      return state
  }
}