module.exports = {
  pathPrefix: '/',
  title: 'Disch Rémy: Artist, Paris, France',
  titleAlt: 'Disch Rémy',
  description: 'Rémy Disch is a French contemporary figurative painter',
  headline: 'Disch Rémy',
  url: 'https://www.dischr.com',
  logo: '/logos/remy-disch11.png',
  ogLanguage: 'en_US',

  // Prismic.io
  PrismicRepository: 'dischr',

  // JSONLD / Manifest
  favicon: 'static/favicon.png',
  shortName: 'dischr',
  author: 'Disch Rémy',
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',
  facebook: 'dischr.com',
  googleAnalyticsID: 'UA-145816033-1',
  skipNavId: 'reach-skip-nav'
}
