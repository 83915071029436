
export const addItem = (
  {
    id = 0,
    uid = '',
    title = '',
    description = '',
    amount = 0,
    reduction = 0
  }
) => ({
  type: 'ADD_ITEM',
  item: {
    id,
    uid,
    title,
    description,
    amount,
    reduction
  }
});

export const removeItem = ({ id } = {}) => ({
  type: 'REMOVE_ITEM',
  id
});

export const init = () => ({
  type: 'INIT'
});

export const clearCart = () => ({
  type: 'CLEAR'
});