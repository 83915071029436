import "firebase/database"
import provider from "./src/store/provider"
import LocaleWrap from './wrapPageElement'
import jQuery from "jquery";

if (typeof window !== 'undefined') {
    window.$ = window.jQuery = jQuery;
    require("xzoom");
}

export const wrapRootElement = provider;
export const wrapPageElement = LocaleWrap
