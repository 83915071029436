const theme = {
  colors: {
    primary: '#3D63AE',
    bg: '#f2f2f2',
    black: '#494949',
    greyLight: '#EBEDF2',
    greyBlue: '#a2bce2',
    grey: '#595C62',
    greyDark: '#303643',
    greyDarker: '#1c252b',
    light: '#9d9d9d',
    mediumGrey: '#888888'
  },
  fontFamily: "'Source Sans Pro','Roboto','Helvetica', 'Arial', sans-serif",
  maxWidth: '1072px',
  maxWidthText: '720px',
  breakpoints: {
    xxs: '320px',
    xs: '420px',
    xsLandscape: '668px',
    s: '640px',
    m: '823px',
    l: '1024px',
  },
}

export default theme
