
const initState = {
  items: [],
  removedItems: [],
  message: ''
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'ORDERS_FETCH': 
      return { ...state, items: action.items};
    case 'SET_REMOVED_ITEMS': 
      return { ...state, removedItems: action.removedItems};
    case 'SET_MESSAGE': 
      return { ...state, message: action.message};
    default:
      return state
  }
}