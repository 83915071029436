// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-category-jsx": () => import("/opt/build/repo/src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-filter-jsx": () => import("/opt/build/repo/src/templates/filter.jsx" /* webpackChunkName: "component---src-templates-filter-jsx" */),
  "component---src-templates-catalog-jsx": () => import("/opt/build/repo/src/templates/catalog.jsx" /* webpackChunkName: "component---src-templates-catalog-jsx" */),
  "component---src-templates-m-catalog-jsx": () => import("/opt/build/repo/src/templates/m/catalog.jsx" /* webpackChunkName: "component---src-templates-m-catalog-jsx" */),
  "component---src-templates-shop-item-jsx": () => import("/opt/build/repo/src/templates/shopItem.jsx" /* webpackChunkName: "component---src-templates-shop-item-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-2022-09-12-jsx": () => import("/opt/build/repo/src/pages/2022-09-12.jsx" /* webpackChunkName: "component---src-pages-2022-09-12-jsx" */),
  "component---src-pages-2022-11-19-jsx": () => import("/opt/build/repo/src/pages/2022-11-19.jsx" /* webpackChunkName: "component---src-pages-2022-11-19-jsx" */),
  "component---src-pages-2023-02-04-jsx": () => import("/opt/build/repo/src/pages/2023-02-04.jsx" /* webpackChunkName: "component---src-pages-2023-02-04-jsx" */),
  "component---src-pages-2023-03-11-jsx": () => import("/opt/build/repo/src/pages/2023-03-11.jsx" /* webpackChunkName: "component---src-pages-2023-03-11-jsx" */),
  "component---src-pages-2023-11-26-jsx": () => import("/opt/build/repo/src/pages/2023-11-26.jsx" /* webpackChunkName: "component---src-pages-2023-11-26-jsx" */),
  "component---src-pages-2024-11-10-jsx": () => import("/opt/build/repo/src/pages/2024-11-10.jsx" /* webpackChunkName: "component---src-pages-2024-11-10-jsx" */),
  "component---src-pages-cart-jsx": () => import("/opt/build/repo/src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-conditions-utilisation-des-donnees-personnelles-jsx": () => import("/opt/build/repo/src/pages/conditions-utilisation-des-donnees-personnelles.jsx" /* webpackChunkName: "component---src-pages-conditions-utilisation-des-donnees-personnelles-jsx" */),
  "component---src-pages-delivery-jsx": () => import("/opt/build/repo/src/pages/delivery.jsx" /* webpackChunkName: "component---src-pages-delivery-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-la-lettre-jsx": () => import("/opt/build/repo/src/pages/la-lettre.jsx" /* webpackChunkName: "component---src-pages-la-lettre-jsx" */),
  "component---src-pages-murmures-jsx": () => import("/opt/build/repo/src/pages/murmures.jsx" /* webpackChunkName: "component---src-pages-murmures-jsx" */),
  "component---src-pages-payment-jsx": () => import("/opt/build/repo/src/pages/payment.jsx" /* webpackChunkName: "component---src-pages-payment-jsx" */),
  "component---src-pages-press-jsx": () => import("/opt/build/repo/src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-shop-jsx": () => import("/opt/build/repo/src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-terms-and-conditions-of-sale-jsx": () => import("/opt/build/repo/src/pages/terms-and-conditions-of-sale.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-of-sale-jsx" */)
}

